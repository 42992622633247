import * as React from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { DataPropsList } from "../../contracts/data-props-list"
import MainCard from "../../components/main-card"

const GrupyParafialne: React.FC<PageProps<DataPropsList>> = ({
  data,
  location,
}) => {
  const siteTitle = data.site.siteMetadata?.title || "Tytuł"
  const posts = data.allMarkdownRemark.nodes.sort((a, b) =>
    a.frontmatter.title > b.frontmatter.title
      ? 1
      : b.frontmatter.title > a.frontmatter.title
      ? -1
      : 0
  )
  const pageTitle = "Grupy parafialne"

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title={pageTitle} />
        <p>Nie ma żadnych wpisów.</p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={pageTitle} />
      <h1>{pageTitle}</h1>
      <div className="max-w-7xl mx-auto">
        <div className="max-w-2xl mx-auto lg:max-w-none">
          <div className="space-y-12 lg:space-y-0 lg:grid lg:grid-cols-1 lg:gap-x-6 gap-y-10">
            {posts.map(post => {
              return (
                <MainCard
                  key={post.fields.slug}
                  thumbnail={post.frontmatter.thumbnail}
                  title={post.frontmatter.title || post.fields.slug}
                  date={post.frontmatter.date}
                  slug={post.fields.slug}
                  description={post.frontmatter.description || post.excerpt}
                />
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default GrupyParafialne

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "grupy-parafialne" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMMM YYYY", locale: "pl")
          title
          description
        }
      }
    }
  }
`
